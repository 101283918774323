import * as React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

import Styles from './index.module.css';
import Hanreiimg from '../../components/hanrei-img-kokudo';



const Table = props => (
<div>
    <div className={Styles.user}>
      <div className={Styles.description}>
        <h2 className={Styles.username}><span className={Styles.themename}>{props.themename}</span>{props.username}</h2>
        <p className={Styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <Link to={props.url}>
    </Link>
</div>
)
{/*
      <Table
        url="/sdgs"
        themename="新しい順"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/?new"
        excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />
 */}




const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const About: React.FunctionComponent<AboutProps> = (props) => {
  const Data = useStaticQuery(graphql`
  query {
    avatar: file(absolutePath: { regex: "/about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1770, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        author
        about
      }
    }
  }
`);

  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
  <div className={Styles.block}>

    <div className={Styles.table}>
    <div className={Styles.head}>
凡例
    </div>
<div className={Styles.item}>
      <div className={Styles.img}>
        <Hanreiimg
          no={props.no}
          title={props.title}
          date={props.date}
          sdgs_no={props.sdgs_no}
          sdgs_main={props.sdgs_main}
          sdgs_subcat={props.sdgs_subcat}
          kou_tisou_pt={props.kou_tisou_pt}
          kou_tisou_chp={props.kou_tisou_chp}
          kou_tisou_sec={props.kou_tisou_sec}
          kou_tisou_sbsec={props.kou_tisou_sbsec}
          kou_tisou_title={props.kou_tisou_title}
          kou_titan_pt={props.kou_titan_pt}
          kou_titan_chp={props.kou_titan_chp}
          kou_titan_sec={props.kou_titan_sec}
          kou_titan_sbsec={props.kou_titan_sbsec}
          kou_titan_title={props.kou_titan_title}
          description={props.description}
          height_name={props.height_name}
          height_origin={props.height_origin}
          height_origin_note={props.height_origin_note}
          height_origin_url_name={props.height_origin_url_name}
          height_origin_url_name2={props.height_origin_url_name2}
          height_origin_url={props.height_origin_url}
          height_origin_url2={props.height_origin_url2}
          color_name={props.color_name}
          color_origin_note={props.color_origin_note}
          color_origin={props.color_origin}
          color_origin_url_name={props.color_origin_url_name}
          color_origin_url2_name={props.color_origin_url2_name}
          color_origin_url={props.color_origin_url}
          color_origin_url2={props.color_origin_url2}
          sokuchi={props.sokuchi}
          mesh_size={props.mesh_size}
          year_display={props.year_display}
          honbun={props.honbun}
          chyusyaku={props.chyusyaku}
          hosoku={props.hosoku}
          kml_file={props.kml_file}
          hanrei1_color={props.hanrei1_color}
          hanrei2_color={props.hanrei2_color}
          hanrei3_color={props.hanrei3_color}
          hanrei4_color={props.hanrei4_color}
          hanrei1_name={props.hanrei1_name}
          hanrei2_name={props.hanrei2_name}
          hanrei3_name={props.hanrei3_name}
          hanrei4_name={props.hanrei4_name}
          height_ratio={props.height_ratio}
          kokudo_hanrei1_name={props.kokudo_hanrei1_name}
          kokudo_hanrei2_name={props.kokudo_hanrei2_name}
          kokudo_hanrei3_name={props.kokudo_hanrei3_name}
          kokudo_hanrei4_name={props.kokudo_hanrei4_name}
          kokudo_height_ratio={props.kokudo_height_ratio}
        />
      </div>
    </div>

    </div>

    </div>

  );
};
 
export default About;



